<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              v-on="on"
              color="success"
              @click.stop="newDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new Document Type</span>
        </v-tooltip>
        <UserNew
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="lists()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat>
          <v-card-text>
            <v-data-table
              loader-height="2"
              :loading="loading"
              :headers="headers"
              :items="userslist"
              :items-per-page="9"
              :footer-props="{ itemsPerPageOptions: [9, 18, 27] }"
            >
              <template v-slot:item.fullname="{ item }">
                {{ item.first_name | capitalize }}
                {{ item.last_name | capitalize }}
              </template>

              <template v-slot:item.userRoleName="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      small
                      v-on="on"
                      color="info"
                      @click.stop="$set(roleDialog, item.id, true)"
                    >
                      {{ item.userRoleName | capitalize }}
                    </v-btn>
                  </template>
                  <span>Update Role</span>
                </v-tooltip>
                <UserRole
                  v-if="roleDialog[item.id]"
                  :id="item.id"
                  :roleDialog="roleDialog[item.id]"
                  @closeRole="closeRole"
                  @submitRole="submitRole"
                />
              </template>

              <template v-slot:item.active="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      fab
                      depressed
                      dark
                      x-small
                      color="primary"
                      @click.stop="$set(statusDialog, item.id, true)"
                    >
                      <v-icon :color="item.active ? 'blue' : 'red'">
                        {{ item.active ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>View Active status</span>
                </v-tooltip>
                <UserStatus
                  v-if="statusDialog[item.id]"
                  :id="item.id"
                  :name="item.first_name"
                  :statusDialog="statusDialog[item.id]"
                  @closeStatus="closeStatus"
                  @submitStatus="submitStatus"
                />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      v-on="on"
                      fab
                      depressed
                      dark
                      x-small
                      color="primary"
                      @click.stop="$set(viewDialog, item.id, true)"
                    >
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Details</span>
                </v-tooltip>
                <UserView
                  v-if="viewDialog[item.id]"
                  :id="item.id"
                  :viewDialog="viewDialog[item.id]"
                  @closeView="closeView"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      v-on="on"
                      fab
                      depressed
                      dark
                      x-small
                      color="success"
                      @click.stop="$set(updateDialog, item.id, true)"
                    >
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit </span>
                </v-tooltip>
                <UserEdit
                  v-if="updateDialog[item.id]"
                  :id="item.id"
                  :updateDialog="updateDialog[item.id]"
                  @closeUpdate="closeUpdate"
                  @submitUpdate="submitUpdate"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      v-on="on"
                      fab
                      depressed
                      dark
                      x-small
                      color="info"
                      @click.stop="$set(repassDialog, item.id, true)"
                    >
                      <v-icon dark> mdi-lock-reset </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit </span>
                </v-tooltip>
                <UserPass
                  v-if="repassDialog[item.id]"
                  :id="item.id"
                  :fname="item.first_name"
                  :lname="item.last_name"
                  :repassDialog="repassDialog[item.id]"
                  @closeRepass="closeRepass"
                  @submitRepass="submitRepass"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import UserNew from "../../../components/accounts/Users/newone.vue";
import UserStatus from "../../../components/accounts/Users/status.vue";
import UserView from "../../../components/accounts/Users/view.vue";
import UserEdit from "../../../components/accounts/Users/update.vue";
import UserPass from "../../../components/accounts/Users/password_reset.vue";
import UserRole from "../../../components/accounts/Users/roles.vue";

export default {
  components: { UserNew, UserStatus, UserView, UserEdit, UserRole, UserPass },
  data() {
    return {
      loading: false,
      statusDialog: {},
      viewDialog: {},
      updateDialog: {},
      roleDialog: {},
      repassDialog: {},
      newDialog: false,
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: { name: "admin.dash" },
        },
        {
          text: "Admin Users",
          disabled: true,
          href: "breadcrumbs_dashboard",
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      headers: [
        { text: "name", value: "fullname" },
        { text: "email", value: "email" },
        { text: "mobile phone", value: "mobile_phone" },
        { text: "Role", value: "role", align: "center" },
        { text: "active", value: "active", align: "center" },
        { text: "actions", value: "actions", align: "center" },
      ],
      userslist: [],
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.accounts
        .roleType("root")
        .then((response) => {
          console.log(response.data);
          this.userslist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.accounts
        .addAdmin(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.newDialog = p.state;
    },
    closeStatus(p) {
      this.$set(this.statusDialog, p.id, p.state);
    },
    submitStatus(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .status(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.statusDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .editprofile(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.updateDialog, p.id, p.state);
    },
    closeRole(p) {
      this.$set(this.roleDialog, p.id, p.state);
    },
    submitRole(p) {
      // console.log(p);
      let usersId = p.id;
      let rolesId = p.data.rolesId;
      let data = { usersId, rolesId };
      // console.log(data);
      this.snack.bar = true;
      let self = this;
      Restful.user.account
        .role(data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.roleDialog, p.id, p.state);
    },
    closeRepass(p) {
      console.log(p);
      this.$set(this.repassDialog, p.id, p.state);
    },
    submitRepass(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.accounts
        .passReset(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.lists();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.repassDialog, p.id, p.state);
    },
  },
};
</script>
