<template>
  <v-dialog v-model="roleDialog" persistent max-width="400">
    <v-card>
      <v-card-title>Update Role</v-card-title>
      <v-card-text>
        <v-select
          dense
          label="roles"
          :items="datalist"
          item-value="id"
          item-text="name"
          v-model="create.rolesId"
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              :disabled="$v.create.$invalid"
              @click="submit"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    roleDialog: Boolean,
    id: { type: Number, default: 0 },
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      datalist: [],
      create: {
        rolesId: null,
      },
    };
  },
  validations: {
    create: {
      rolesId: {
        required,
      },
    },
  },
  created() {
    this.find();
    this.lists();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.user.account
        .findMe(this.id)
        .then((response) => {
          console.log(response.data);
          this.create.rolesId = response.data.userRoleId;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.roles
        .adminlist()
        .then((response) => {
          console.log(response.data);
          this.datalist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.$emit("closeRole", payload);
    },
    submit() {
      let payload = { id: this.id, state: false, data: this.create };
      this.$emit("submitRole", payload);
    },
  },
};
</script>
