<template>
  <v-dialog v-model="newDialog" persistent max-width="400">
    <v-card>
      <v-card-title>new admin user</v-card-title>
      <v-card-text>
        <v-select
          dense
          label="Gender"
          :items="genderList"
          item-value="id"
          item-text="name"
          v-model="create.gendersid"
        ></v-select>
        <v-select
          dense
          label="salutation"
          :items="saluteList"
          item-value="id"
          item-text="name"
          v-model="create.salutationsid"
        ></v-select>
        <v-text-field
          dense
          label="first name"
          v-model="create.first_name"
        ></v-text-field>
        <v-text-field
          dense
          label="last name"
          v-model="create.last_name"
        ></v-text-field>
        <v-text-field
          dense
          maxLength="10"
          counter
          label="mobile Phone"
          v-model="create.mobile_phone"
        ></v-text-field>
        <v-text-field
          dense
          label="email"
          type="email"
          v-model="create.email"
        ></v-text-field>
        <v-select
          dense
          label="roles"
          :items="datalist"
          item-value="id"
          item-text="name"
          v-model="create.rolesId"
        >
        </v-select>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              :disabled="$v.create.$invalid"
              @click="submit"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      datalist: [],
      saluteList: [],
      genderList: [],
      create: {
        gendersid: null,
        salutationsid: null,
        first_name: null,
        last_name: null,
        email: null,
        mobile_phone: null,
        rolesId: null,
      },
    };
  },
  validations: {
    create: {
      gendersid: {
        required,
      },
      salutationsid: {
        required,
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile_phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      rolesId: {
        required,
      },
    },
  },
  created() {
    this.lists();
    this.salut();
    this.gender();
  },
  methods: {
    salut() {
      this.loading = true;
      let self = this;
      Restful.general.salutations
        .list()
        .then((response) => {
          console.log(response.data);
          this.saluteList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    gender() {
      this.loading = true;
      let self = this;
      Restful.general.genders
        .list()
        .then((response) => {
          console.log(response.data);
          this.genderList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    lists() {
      this.loading = true;
      let self = this;
      Restful.general.roles
        .adminlist()
        .then((response) => {
          console.log(response.data);
          this.datalist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let gendersid = this.create.gendersid;
      let salutationsid = this.create.salutationsid;
      let first_name = this.create.first_name;
      let last_name = this.create.last_name;
      let email = this.create.email;
      let mobile_phone = this.create.mobile_phone;
      let rolesId = this.create.rolesId;
      let data = {
        gendersid,
        salutationsid,
        first_name,
        last_name,
        email,
        mobile_phone,
        rolesId,
      };
      // console.log(data);
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
