<template>
  <v-dialog v-model="statusDialog" persistent max-width="400">
    <v-card :loading="laoding" loader-height="2">
      <v-card-title>{{ name }} Status </v-card-title>
      <v-card-text>
        <v-list subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Active </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-switch
                v-model="myStatus"
                :label="`  .   ${myStatus ? ' Yes' : ' No'}`"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "./../../../mixins/utils";
export default {
  props: {
    statusDialog: Boolean,
    id: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      laoding: false,
      myStatus: false,
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      // console.log(this.id);
      Restful.accounts
        .findMe(this.id)
        .then((response) => {
          // console.log(response);
          this.myStatus = response.data.active;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.$emit("closeStatus", payload);
    },
    submit() {
      let active = this.myStatus;
      let data = { active };

      let payload = { id: this.id, state: false, data: data };
      this.$emit("submitStatus", payload);
    },
  },
};
</script>
