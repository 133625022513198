<template>
  <v-dialog v-model="viewDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-img :src="myImg(view.myPix)" width="200"></v-img>
          </v-col>
          <v-col cols="12" sm="8">
            <div class="mylist">
              <ul>
                <li>
                  <div class="myleft">full name</div>
                  <div class="myright">
                    {{ view.firstName }} {{ view.lastName }}
                  </div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">mobilePhone</div>
                  <div class="myright">{{ view.mobilePhone }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">email</div>
                  <div class="myright">{{ view.email }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">active</div>
                  <div class="myright">{{ view.active ? "Yes" : "No" }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">created</div>
                  <div class="myright">{{ view.created | myDating }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">updated</div>
                  <div class="myright">{{ view.updated | myDating }}</div>
                  <div class="clearall"></div>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
import Utils from "./../../../mixins/utils";
export default {
  props: {
    viewDialog: Boolean,
    id: { type: Number, default: 0 },
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      view: {
        myPix: null,
        firstName: null,
        lastName: null,
        mobilePhone: null,
        email: null,
        active: null,
        created: null,
        updated: null,
      },
    };
  },
  created() {
    this.find();
  },
  methods: {
    myImg(img) {
      if (img === "" || img === null) {
        return this.dataUrl;
      } else {
        return img;
      }
    },
    find() {
      this.loading = true;
      let self = this;
      Restful.accounts
        .findMe(this.id)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.view.myPix = data.my_pix;
          this.view.firstName = data.first_name;
          this.view.lastName = data.last_name;
          this.view.mobilePhone = data.mobile_phone;
          this.view.email = data.email;
          this.view.active = data.active;
          this.view.created = data.created;
          this.view.updated = data.updated;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
