<template>
  <v-dialog v-model="updateDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>Update User </v-card-title>
      <v-card-text>
        <v-text-field
          dense
          label="first name"
          v-model="create.firstName"
        ></v-text-field>
        <v-text-field
          dense
          label="last name"
          v-model="create.lastName"
        ></v-text-field>
        <v-text-field
          dense
          maxLength="10"
          counter
          label="mobile Phone"
          v-model="create.mobilePhone"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submitDialog">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    updateDialog: Boolean,
    id: { type: Number, default: 0 },
  },
  data() {
    return {
      responseMessage: null,
      loading: false,
      datalist: [],
      create: {
        firstName: null,
        lastName: null,
        mobilePhone: null,
      },
    };
  },
  validations: {
    create: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      mobilePhone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.accounts
        .findMe(this.id)
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.create.firstName = data.first_name;
          this.create.lastName = data.last_name;
          this.create.mobilePhone = data.mobile_phone;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.$emit("closeUpdate", payload);
    },
    submitDialog() {
      let first_name = this.create.firstName;
      let last_name = this.create.lastName;
      let mobile_phone = this.create.mobilePhone;

      let data = { first_name, last_name, mobile_phone };
      console.log(data);
      let payload = { id: this.id, state: false, data: data };
      this.$emit("submitUpdate", payload);
    },
  },
};
</script>
