<template>
  <v-dialog v-model="repassDialog" persistent max-width="400">
    <v-card>
      <v-card-title>remove {{ fname }} {{ lname }}</v-card-title>
      <v-card-text>
        Are you sure you want to change this user's password?
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    repassDialog: Boolean,
    id: { type: Number, default: 0 },
    fname: { type: String, default: null },
    lname: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
    };
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.id, state: false };
      this.$emit("closeRepass", payload);
    },
    submit() {
      let id = this.id;
      let data = {id};
      let payload = { id: this.id, state: false, data: data };
      this.$emit("submitRepass", payload);
    },
  },
};
</script>
